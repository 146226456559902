/***************************

    Component: Buttons

***************************/

.buttons {
    height: 48px;
    padding: 8px 22px;
    
    border-radius: 54px;
    text-decoration: none;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: rem(20px);
    cursor: pointer;
    @include media('<=sm'){
        padding: 8px 35px;
    }
  }
  
  a.btn{
    display: inline-block;
    line-height: 32px;
  }
  .btn {
    &-primary {
      @extend .buttons;
      background-color: var(--c-yellow);
      color: var(--c-dark);
      transition: background-color 0.3s ease-out;
      white-space: nowrap;
      
      @include media('<=sm'){
        letter-spacing: -1px;
      }
  
      &:hover, &:focus{
          background-color: var(--c-yellow);
      }
      &:active {
          background-color: var(--c-yellow);
      }
  
      
    }
    &-secondary {
      @extend .buttons;
      background: transparent;
      padding: 0;
      transition: opacity 0.3s ease-out;
  
      &:hover, &:focus{
          opacity: 0.5;
      }
      &:active {
          opacity: 0.3;
      }
  
      @include media('<md'){
          font-size: rem(16px);
      }
    }
  }