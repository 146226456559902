.Stores{
    color: white;
    position: relative;
    overflow: hidden;

    
    &__container{
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 200px 0;
        @include media('<=sm'){
            padding: 164px 0;
        }
    }

    &__marquee{
        position: absolute;
        z-index: zindex(below);
        top: 40px;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 34px;
        margin: 0 auto;
        overflow: hidden;
        $marqueeWidth: 1600px;
        max-width: $marqueeWidth;

        @include media('<=sm'){
            top: 0px;
        }

        &:after{
            @include media('<#{$marqueeWidth}'){
                display: none;
            }
            pointer-events: none;
            content: "";
            position: absolute;
            top: 0;
            left: -1px;
            height: 100%;
            width: 20vw;
            z-index: zindex(above);
            background: linear-gradient(90deg, rgba(13,19,33,1) 2%, rgba(13,19,33,0) 100%);
        }
        &:before{
            @include media('<#{$marqueeWidth}'){
                display: none;
            }
            pointer-events: none;
            content: "";
            position: absolute;
            top: 0;
            right: -1px;
            height: 100%;
            width: 300px;
            z-index: zindex(above);
            background: linear-gradient(90deg, rgba(13,19,33,0) 0%, rgba(13,19,33,1) 98%);
        }
        
        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            column-gap: 40px;
            width: 100%;
            @include media('<=sm'){
                margin-left: 30px;
            }
            &:nth-child(2){
                margin-left: -30px;
                @include media('<=sm'){
                    margin-left: 0;
                }
            }
            &:nth-child(3){
                margin-left: -60px;
                @include media('<=sm'){
                    margin-left: -30px;
                }
            }
        }

        li{
            background-color: var(--c-dark2);
            border-radius: 6px;
            width: 360px;
            height: 148px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                padding: 30px 40px;
                width: 80%;
                height: 80%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &__text{
        margin: 10px 0 20px;
        max-width: 450px;
        @include media('<=sm'){
            margin: 10px auto 20px auto;
        }
    }

    &__content{
        margin-left: grid(2,24);
        @include media('<=sm'){
            margin-left: 0;
            text-align: center;
            padding: 0 20px;
        }
    }

    .p1{
        max-width: 700px;
    }
}