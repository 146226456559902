.Header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &__content{
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 50px 20px 40px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    &__logo{
        width: 168px;
        height: 66px;
        @include media('<=sm'){
            width: 130px;
            height: 51px;
        }
    }

    &__links{
        margin-top: -10px;
    }

    .btn-primary{
        font-size: rem(16px);
        height: 33px;
        padding: 7px 14px;
        margin-left: 18px;
        line-height: 18px;
        @include media('<=sm'){
            display: none;
        }
    }
    .btn-secondary{
        font-size: rem(16px);
        color: white;
    }
}