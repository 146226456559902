.Offer{
    color: white;
    &__container{
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 100px 0 160px;
        @include media('<=sm'){
            padding: 88px 0 114px;
        }
    }

    &__title{
        @include media('<=sm'){
            text-align: center;
        }
    }

    &__content{
        margin-left: grid(2,24);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        font-size: rem(20px);
        line-height: rem(30px);

        @include media('<=sm'){
            margin-left: 0;
            padding: 0 20px;
            flex-wrap: nowrap;
            flex-direction: column;
        }

        &__left{
            flex-shrink: 0;
        }
        &__right{
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media('<=sm'){
                width: 100%;
            }
        }
    }

    &__list{
        margin-top: 4px;
        @include media('<=sm'){
            margin-top: 26px;
        }
        li{
            margin-top: 9px;
            line-height: 25px;
            background-image: url('~/src/images/chevron.svg');
            background-repeat: no-repeat;
            background-position: center left;
            padding-left: 38px;
            max-width: 500px;
            @include media('<md'){
                max-width: 400px;
                }
                @include media('<=sm'){
                margin-top: 12px;
                max-width: 320px;
                font-size: rem(14px);
                padding-left: 30px;
                background-image: url('~/src/images/chevron--mobile.svg');
            }
        }
    }

    &__card{
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        transform: rotate(-2deg);
        width: 252px;

        @include media('<=sm'){
            width: 100%;
            margin-top: 20px;
        }

        &__content{
            color: var(--c-dark);
            text-align: center;
            padding: 35px 0;
            @include media('<=sm'){
                padding: 30px 10px 45px 10px;
            }
            p{
                margin: 0 auto;
                max-width: 140px;
                margin-bottom: 15px;
                @include media('<=sm'){
                    max-width: none;
                    font-size: rem(20px);
                }
            }
            .btn{
                margin-top: 5px;
            }
        }
        
        &__title{
            padding: 20px;
            background-color: var(--c-purple);
            svg{
                margin: 0 auto;
                display: block;
            }
        }
    }
}