// --------------------------------------------------
// Colors
// --------------------------------------------------
:root {
    --c-yellow: #FFE3A9;
    --c-dark: #0D1321;
    --c-dark2: #101729;
    --c-purple: #7F2CCB;
    --c-light: #FFFFFF;
    --c-grey: #F3F3F3;
    --c-transparent: rgba(255, 255, 255, 0);
}
