.Footer {
    background-color: var(--c-dark);
    color: var(--c-yellow);
    background-image: url('~/src/images/bg-pattern--footer.svg');
    background-repeat: repeat;
    background-position: top left;

    a{
        color: white;
        text-decoration: none;
    }

    &__logo{
        fill: var(--c-yellow);
    }

    &__container{
        max-width: $maxWidth;
        margin: 0 auto;
        width: 100%;
        padding-block: 76px 19px;
        display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media('<md'){
            padding-block: 64px 59px;
        }
    }

    &__links{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 34px;
        margin-top: 46px;
        
        @include media('<md'){
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            row-gap: 15px;
            
            .p2{
                font-size: rem(16px);
            }
        }
    }

    &__socials{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 21px;
        margin-block: 40px 30px;
        svg{
            fill: white;
        }
        
        li{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 0;
            background-color: #263149;
        }
    }

    &__copyright{
        color: #263149;
        font-size: rem(14px);
        @include media('<md'){
            font-size: rem(14px);
            max-width: 250px;
            text-align: center;
        }
    }
}
