// --------------------------------------------------
// Default
// --------------------------------------------------

.noselect {
    user-select: none;
}

// -------------------------
// Reset the box-sizing
// -------------------------

html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: Poppins;
}
html{
   
	&.is-scrolldisabled{
        position: fixed;
        overflow-y: scroll;
        width: 100%;
    }
}


* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

// -------------------------
// Body reset
// -------------------------

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -moz-font-feature-settings: "liga", "kern";
    text-rendering: optimizelegibility;

    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    background-color: var(--c-dark);
}

::-moz-selection {
    color: white;
    background: black;
}

::selection {
    color: white;
    background: black;
}

.disable-select {
    user-select: none;
}

img {
    display: block;
    -ms-interpolation-mode: bicubic;
}
