.Cover{
    height: 80vh;
    max-height: 800px;
    width: 100%;
    background-image: url('~/src/images/background.jpg');
    background-size: cover;
    background-position: bottom center;

    display: flex;
    align-items: center;
    justify-content: center;

    &__content{
        text-align: center;
        color: white;

        .h1{
            position: relative;
            z-index: 1;
            top: 26px;
            left: -14px
        }
    }

    &__badge{
        width: 350px;
        height: 139px;
        margin: 0 auto;
        transform: rotate(-4.5deg);
        
        @include media('<=sm'){
            width: 250px;
            height: 100px;
        }
    }
    
    &__text{
        max-width: 350px;
        margin-top: 23px;
        @include media('<=sm'){
            margin-top: 36px;
            padding: 0 20px;
        }
    }

    &__button{
        margin-top: 8px;
        display: inline-block;
        @include media('<=sm'){
            margin-top: 25px;
        }
    }
}