.Popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: zindex(popup);
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s $ease-out-quint, visibility 0.3s;

    &__container{
        width: 100%;
        max-width: 400px;
        padding: 20px
    }

    &__content{
        display: flex;
        width: 100%;
        height: 80%;
        overflow: hidden;
        max-width: 600px;
        max-height: 700px;
        height: 80vh;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 15px;
        position: relative;
        background-color: #FFF8EA;
        padding-top: 40px;
        &.login{
            max-height: 470px;
        }
        iframe{
            height: 100% !important;
        }
    }

    &__close{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: zindex(above);
    }

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s $ease-out-quint, visibility 0s;

        .APopin__container {
        transform: translateY(0);
        }
    }
}