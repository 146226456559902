// --------------------------------------------------
// Fonts
// --------------------------------------------------

$fonts: (
  serif: #{"Dahlia", "Times New Roman", "serif", "arial"},
  sans-serif: #{"Sofia", "sans serif", "arial"},
  Dahlia: #{"Dahlia", "calibri", "arial"},
  Sofia: #{"Sofia", "sans serif", "arial"},
);


.h1{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: rem(92px);
    color: inherit;

    @include media('<1100px'){
        font-size: rem(80px);
    }

    @include media('<md'){
        font-size: rem(64px);
        letter-spacing: 1px;
        line-height: rem(77px);
    }
}

.h2{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: rem(44px);
    line-height: rem(66px);
    color: inherit;
    
    @include media('<md'){
        line-height: 50px;
        font-size: rem(32px);
    }
}

.p1{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: rem(20px);
    line-height: rem(31px);
    color: inherit;

    @include media('<md'){
        font-size: rem(16px);
        line-height: rem(24px);
    }
}

.p2{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: inherit;
    // @include media('<md'){
    //     font-size: rem(16px);
    //     line-height: rem(24px);
    // }
}